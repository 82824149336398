import React from "react";
import { Card, CardGroup, Container, Row, Col, Table } from "react-bootstrap";
import Footer from "../components/footer";
import App from "../containers/App";
import "./monitoria.css";

const monitoria = () => (
  <App>
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "50px",
        marginTop: "20px",
      }}
    >
      <Row>
        <Col style={{ marginTop: "30px" }}>
          <p className="monitoria-titulo">Monitoria</p>
          <p className="monitoria-p">2º Semestre de 2024</p>
        </Col>
      </Row>
    </Container>
	
    <Container style={{ marginBottom: "50px", textAlign: "justify" }}>
      <Row>
        <Col md={{ span: 12 }} style={{ marginBottom: "40px" }}>
			<p className="monitoria-subtitulo" style={{ marginTop: "50px" }}>Editais</p>
			<p className="monitoria-p">
				<a
					id="cor2"
					target="_blank" rel="noopener noreferrer"
					href="https://sjc.fatec.sp.gov.br/downloads/monitoria/2024/Edital Interno do Processo Seletivo para o Programa de Monitoria -2024-2.pdf"
				>
					- Edital nº 01/2024 – Processo Seletivo para o Programa de Monitoria
				</a><br />
				<a
					id="cor2"
					target="_blank" rel="noopener noreferrer"
					href="https://sjc.fatec.sp.gov.br/downloads/monitoria/2024/Resultado edital de monitoria - 2024-2.pdf"
				>
					- Resultado do edital nº 01/2024
				</a>
			</p>
			
			<p className="monitoria-p">
				<a
					id="cor2"
					target="_blank" rel="noopener noreferrer"
					href="https://sjc.fatec.sp.gov.br/downloads/monitoria/2024/Edital Interno 02-2024  do Processo Seletivo para o Programa de Monitoria -2024-2.pdf"
				>
					- Edital nº 02/2024 – Processo Seletivo para o Programa de Monitoria
				</a><br />
				<a
					id="cor2"
					target="_blank" rel="noopener noreferrer"
					href="https://sjc.fatec.sp.gov.br/downloads/monitoria/2024/Resultado Edital 02-2024  do Processo Seletivo para o Programa de Monitoria -2024-2.pdf"
				>
					- Resultado do edital nº 02/2024
				</a>
			</p>
			
			<p className="monitoria-p">
				<a
					id="cor2"
					target="_blank" rel="noopener noreferrer"
					href="https://sjc.fatec.sp.gov.br/downloads/monitoria/2024/Edital Interno 03 do Processo Seletivo para o Programa de Monitoria -2024-2.pdf"
				>
					- Edital nº 03/2024 – Processo Seletivo para o Programa de Monitoria
				</a><br />
				<a
					id="cor2"
					target="_blank" rel="noopener noreferrer"
					href="https://sjc.fatec.sp.gov.br/downloads/monitoria/2024/Resultado Edital 03-2024  do Processo Seletivo para o Programa de Monitoria -2024-2.pdf"
				>
					- Resultado do edital nº 03/2024
				</a>
			</p>
        </Col>
      </Row>
    </Container>
	
	
    <Container style={{ marginBottom: "50px", textAlign: "justify" }}>
      <Row>
        <hr />
        <Col md={{ span: 12 }} style={{ marginBottom: "40px" }}>
          <p className="monitoria-subtitulo" style={{ marginTop: "50px" }}>Anexos</p>
          <p className="monitoria-p">
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/monitoria/deliberacao_ceeteps_40_2018-02-08_monitoria.pdf"
            >
              - Deliberação CEETEPS nº 40 de 08/02/2018
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/monitoria/instrucao_normativa_cesu_07_2018-07-16.pdf"
            >
              - Instrução Normativa CESU nº 07 de 16/07/2018
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/monitoria/anexo_02_relatorio_final.docx"
            >
              - Anexo 2 - Relatório Final
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/monitoria/anexo_03_ficha_inscricao_monitoria.docx"
            >
              - Anexo 3 - Ficha de Inscrição Monitoria
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/monitoria/anexo_05_lista_presenca.docx"
            >
              - Anexo 5 - Lista de Presença
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/monitoria/anexo_06_registro_conteudo.docx"
            >
              - Anexo 6 - Registro de Conteúdo
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/monitoria/anexo_07_ficha_avaliacao_projeto.docx"
            >
              - Anexo 7 - Ficha de Avaliação do Projeto
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/monitoria/anexo_08_ficha_inscricao_monitoria_iniciacao.docx"
            >
              - Anexo 8 - Ficha de Inscrição Monitoria Iniciação
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/monitoria/anexo_09_instrucoes_elaboracao_projeto_pesquisa.docx"
            >
              - Anexo 9 - Instruções para a Elaboração do Projeto de Pesquisa
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/monitoria/anexo_10_plano_trabalho_(aluno).docx"
            >
              - Anexo 10 - Plano de Trabalho (Aluno)
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/monitoria/anexo_11_plano_trabalho_(orientador).docx"
            >
              - Anexo 11 - Plano de Trabalho (Orientador)
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/monitoria/anexo_12_instrucoes_elaboracao_relatorio_parcial.docx"
            >
              - Anexo 12 - Instruções para a Elaboração do Relatório Parcial
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/monitoria/anexo_13_ficha_acompanhamento_atividades_propostas.docx"
            >
              - Anexo 13 - Ficha de Acompanhamento das Atividades Propostas
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/monitoria/anexo_14_instrucoes_elaboracao_resumo_expandido.docx"
            >
              - Anexo 14 - Instruções para a Elaboração do Resumo Expandido
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/monitoria/anexo_15_instrucoes_elaboracao_relatorio_final_monitoria_iniciacao.docx"
            >
              - Anexo 15 - Instruções para a Elaboração do Relatório Final
              Monitoria - Iniciação
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/monitoria/anexo_16_formulario_avaliacao_resumo_expandido_CEPE.docx"
            >
              - Anexo 16 - Formulário de Avaliação do Resumo Expandido - CEPE
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/monitoria/anexo_17_formulario_avaliacao_relatorio_final_CEPE.docx"
            >
              - Anexo 17 - Formulário de Avaliação do Relatório Final - CEPE
            </a>
          </p>
        </Col>
      </Row>
    </Container>
    <Footer />
  </App>
);

export default monitoria;
