import React from "react";
import { Card, CardGroup, Container, Row, Col, Table } from "react-bootstrap";
import Footer from "../components/footer";
import App from "../containers/App";
import "./normas-para-tg.css";

const TG = () => (
  <App>
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "50px",
        marginTop: "20px",
      }}
    >
      <Row>
        <Col style={{ marginTop: "30px" }}>
          <p className="tg-titulo">Normas para TG</p>
        </Col>
      </Row>
    </Container>

    <Container style={{ marginBottom: "50px", textAlign: "justify" }}>
      <Row>
        <Col md={{ span: 12 }} style={{ marginBottom: "40px" }}>
          <p className="moodle-p">
            Abaixo estão inseridos alguns fundamentos básicos para que o
            Trabalho de Graduação da FATEC - SJC seja formalizado.
          </p>
          <p className="monitoria-p">
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/tcc/proc_orientacao_confec_aval_tg.pdf"
            >
              - Orientações sobre Trabalho de Graduação
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/tcc/anexo_2_manual_confeccao_tg.pdf"
            >
              - Anexo 2 - Manual de Trabalho de Graduação - FATEC-SJC
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/tcc/anexo_3_ficha_aval_qualificacao_tg.doc"
            >
              - Anexo 3 - Ficha de avaliação de qualificação de Trabalho de
              Graduação
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/tcc/anexo_4_formalizacao_orientacao_tg.doc"
            >
              - Anexo 4 - Modelo de formalização de orientação do Trabalho de
              Graduação
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/tcc/anexo_5a_modelo_tg.docx"
            >
              - Anexo 5.A - Modelo do Trabalho de Graduação
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/tcc/anexo_5b_modelo_tg_eixo_aeronautico.docx"
            >
              - Anexo 5.B - Modelo do Trabalho de Graduação - Cursos do Eixo
              Aeronáutico
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/tcc/anexo_5c_modelo_tg_pea.docx"
            >
              - Anexo 5.C - Modelo do Trabalho de Graduação - Cursos de Projetos de Estruturas 
              Aeronáuticas
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/tcc/anexo_6_modelo_apresentacao_tg.pptx"
            >
              - Anexo 6 - Modelo da apresentação do Trabalho de Graduação
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/tcc/anexo_7_ficha_avaliacao_defesa.doc"
            >
              - Anexo 7 - Ficha de avaliação de defesa
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/tcc/anexo_8_modelo_relatorio_projeto_integrador_log.docx"
            >
              - Anexo 8 - Modelo de Relatório de Projeto Integrador (Logística)
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/tcc/anexo_9_revisao_notas_competencia_logistica.xlsx"
            >
              - Anexo 9 - Revisão de notas por competência (Logística)
            </a>
			<br />
			<a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/tcc/anexo_10_revisao_de_notas_por_competencia_curso_pea.xlsx"
            >
              - Anexo 10 - Revisão de notas por competência (Projetos de Estruturas Aeronáuticas)
            </a>
          </p>
        </Col>
      </Row>
    </Container>
    <Footer />
  </App>
);

export default TG;
